.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15rem;
  background-color: rgba($color-black, 0.8);
  box-shadow: 1rem 2rem rgba($color-black, 0.1);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &:target {
    opacity: 1;
    visibility: visible;
  }
  &__content {
    @include absCenter;
    width: 75%;
    background-color: $color-white;
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    border-radius: 3px;
    display: table;
    overflow: hidden;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all 0.4s 0.2s;
    // @include respond(tab-port) {
    //   display: none;
    // }
  }
  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  &__close {
    &:link,
    &:visited {
      color: $color-grey;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 3rem;
      text-decoration: none;
      display: inline-block;
      transition: all in 0.2s;
      line-height: 1;
      :hover {
        color: $color-primary;
      }
    }
  }
  &__left {
    width: 33.333333%;
    // float: left;
    display: table-cell;

    @include respond(tab-port) {
      // float: top;
      display: table-row;
    }
  }
  &__right {
    width: 66.666667;
    // float: left;
    display: table-cell;
    vertical-align: middle;
    padding: 3rem 5rem;
    @include respond(tab-port) {
      // float: top;
      display: table-cell;
    }
  }
  &__img {
    display: block;
    width: 100%;
    @include respond(tab-port) {
      display: inline-block;
      width: 50%;
    }
  }
  &__text {
    font-size: 1.4rem;
    margin-bottom: 4rem;

    // -moz-column-count: 2;
    -moz-column-gap: 1rem;
    -moz-column-rule: 1ps solid $color-grey-dark-2;

    // column-count: 2;
    column-gap: 1rem;
    column-rule: 1ps solid $color-grey-dark-2;

    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    @include respond(tab-port) {
      column-count: 1;
    }
  }
}
