.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.35;
  overflow: hidden;
  &__content {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
