.heading-primary {
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  // background-color: aqua;
  // display: inline-block;

  &--main {
    display: block;
    font-size: 3.5rem;
    font-weight: 400;
    letter-spacing: 0.8rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    color: $color-black;
    text-align: center;
    // width: 51.2rem;
    width: 69.5rem;
    /* animation-delay: 2s; */
    /* animation-iteration-count: 3; */
    animation-timing-function: ease-out;

    @include respond(tab-port) {
      letter-spacing: 1rem;
      font-size: 2rem;
      width: auto;
    }
  }

  &--sub {
    display: block;
    font-size: 4rem;
    // font-weight: 400;
    letter-spacing: 0.8rem;
    animation: moveInRight 1s ease-out;
    color: $color-black;
    margin-bottom: 5rem;
    text-align: center;
    font-weight: 900;
    width: 67.5rem;

    @include respond(tab-port) {
      letter-spacing: 0.5rem;
      font-size: 2rem;
      width: auto;
    }
  }
}

.heading-professional {
  font-size: 3.5rem;
  // text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  // background-image: linear-gradient(
  //   to right,
  //   $color-primary-light-1,
  //   $color-primary-dark-1
  // );

  -webkit-background-clip: text;
  background-clip: text;
  color: $professional-color1;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
  &__2 {
    text-transform: none;
  }

  @include respond(tab-port) {
    font-size: 2.5rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }

  &:hover {
    transform: skewY(-0.2deg) skewX(0deg) scale(1.03);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    $color-primary-light-1,
    $color-primary-dark-1
  );

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
  &__2 {
    text-transform: none;
  }

  @include respond(tab-port) {
    font-size: 2.5rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }

  &:hover {
    transform: skewY(-0.2deg) skewX(0deg) scale(1.03);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.heading-secondary2 {
  font-size: 4.5rem;
  max-width: 800px;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    $color-primary-light-1,
    $color-primary-dark-1
  );

  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @include respond(tab-port) {
    font-size: 2.5rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }

  &:hover {
    transform: skewY(-0.2deg) skewX(0deg) scale(1.03);
    text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  }
}

.heading-teritory {
  font-size: $default-font-size;
  font-weight: 500;
  // text-transform: uppercase;
  font-family: "Montserrat", Sans-serif;
}

.paragraph {
  font-size: $default-font-size;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
.paragraph2 {
  font-size: 2.2rem;
  max-width: 60rem;
  display: inline-block;
  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.text-color-white {
  color: white;
}

.navigation-footer {
  background-color: #fff;
  &__icons {
    text-align: center;
    padding: 1rem 0rem 0rem 0rem;
  }
}
