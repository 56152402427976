.careers {
  &__card {
    // background-color: aqua;
    display: flex;
    // box-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.15);
    padding: 10px;
    border-radius: 4px;
    gap: 2rem;
    &__heading {
      font-size: 2.2rem;
      margin-bottom: 1rem;
      font-weight: 400;
      color: $professional-color1;
    }
    &__passage {
      font-size: 1.6rem;
    }
    &__icon {
      height: 8rem;
    }
  }
  &__heading-primary {
    font-size: 2.6rem;
    margin-bottom: 6rem;
    color: $color-black;
  }
  &__benifits {
    &__card {
      text-align: center;
      &__description {
        font-size: 1.8rem;
        color: $color-black;
      }
    }
    &__heading {
      font-size: 4rem;
      color: $professional-color1;
      font-weight: 200;
    }
  }
  &__passionate {
    &__img {
      width: 100%;
      border-radius: 20px;
    }
    &__heading {
      font-size: 3rem;
      color: $professional-color1;
      max-width: 600px;
      display: inline-block;
      font-weight: 200;
      margin-bottom: 1rem;
    }
    &__description {
      font-size: 2rem;
      color: $color-black;
    }

    &__sub-heading {
      font-size: 3rem;
      color: $color-black;
      margin-bottom: 2rem;
      margin-top: 10rem;
      font-weight: 400;
    }
    &__sub-description {
      font-size: 2.4rem;
      color: rgb(65, 64, 64);
    }
  }
}
