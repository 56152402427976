.footer {
  background-color: #021327;
  color: $color-white;
  padding: 2rem 0;
  font-size: 1.4rem;

  @include respond(tab-port) {
    padding: 8rem 0;
  }

  &__logo-box {
    text-align: center;
    margin-bottom: 3rem;

    @include respond(tab-port) {
      margin-bottom: 6rem;
    }
  }
  &__logo {
    background-color: $color-white;
    padding: 10px;
    border-radius: 10px;
    width: 25rem;
    height: auto;
    cursor: pointer;
  }
  &__navigation {
    border-top: 1px solid $color-grey-dark-2;
    padding-top: 1rem;
    display: inline-block;
    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
  }
  &__list {
    list-style: none;
  }
  &__item {
    // display: inline-block;
    // list-style: none;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__link {
    &,
    &:link,
    &visited {
      color: $color-grey-light-1;
      background-color: #021327;
      text-decoration: none;
      // text-transform: uppercase;
      display: inline-block;
      transition: all 0.2s;
    }
    &:hover,
    &:active {
      color: $color-primary;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
      transform: rotate(-2deg) scale(1.3);
    }
  }
  &__copyright {
    border-top: 1px solid $color-grey-dark-2;
    padding-top: 2rem;
    width: 80%;
    float: right;
    @include respond(tab-port) {
      width: 100%;
      text-align: center;
      float: none;
    }
  }
  &__sm-icons-cont {
    text-align: center;
    gap: 30rem;
    margin-top: -4rem;
    background-color: #fff;
  }
  &__sm-icons {
    width: 5rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
}

.all-items {
  margin-top: 5rem;
}

//SLIDER

.slick-slide > div {
  margin: 0 3rem;
}
.slick-list {
  margin: 0 -10px;
}

.our-expertise-slider__image {
  width: 200px;
  height: 80px;
}
.our-expertise-slider {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15px;
  &__heading {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}

/* Apply the animation to the carousel items */
.custom-carousel .swiper-slide {
  text-align: center;
  animation: slideInLeft 0.5s ease-in-out;
}

/* Adjust carousel styling as needed */
.custom-carousel .swiper-container {
  position: static;
}

/* styles.css or your component's stylesheet */
 
.slick-prev:before,
.slick-next:before {
  color: #007bff; /* Example arrow color */
  font-size: 24px;
}

/* Optional: Styling for button hover state */
.slick-prev:hover:before,
.slick-next:hover:before {
  color: #0056b3; /* Example arrow color on hover */
}

/* Increase specificity further by combining class and element selectors */
div.our-expertise-slider .slick-prev:before,
div.our-expertise-slider .slick-next:before {
  color: #007bff; /* Example arrow color */
  font-size: 24px;
}

/* Optional: Styling for button hover state */
div.our-expertise-slider .slick-prev:hover:before,
div.our-expertise-slider .slick-next:hover:before {
  color: #67686a; /* Example arrow color on hover */
}
