.card {
  //FUNCTIONALITY
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 50rem;
  &__side {
    height: 50rem;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.5);
    backface-visibility: hidden;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    &--front {
      background-color: $color-white;
    }
    &--back {
      // background-color: green;
      transform: rotateY(180deg);
      &-1 {
        background-image: linear-gradient(
          to right bottom,
          $color-secondary-light,
          $color-secondary-dark
        );
      }
      &-2 {
        background-image: linear-gradient(
          to right bottom,
          $color-primary-light,
          $color-primary-dark
        );
      }
      &-3 {
        background-image: linear-gradient(
          to right bottom,
          $color-tertiory-light,
          $color-tertiory-dark
        );
      }
    }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }
  &:hover &__side--back {
    transform: rotateY(0);
  }
  //FRONT PAGE STYLING
  &__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode: screen;

    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    &--1 {
      background-image: linear-gradient(
          to right bottom,
          $color-secondary-light,
          $color-secondary-dark
        ),
        url(../../assets/career1.jpg);
    }
    &--2 {
      background-image: linear-gradient(
          to right bottom,
          $color-primary-light,
          $color-primary-dark
        ),
        url(../../assets/career2.jpg);
    }
    &--3 {
      background-image: linear-gradient(
          to right bottom,
          $color-tertiory-light,
          $color-tertiory-dark
        ),
        url(../../assets/career3.jpg);
    }
  }
  &__heading {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    color: $color-white;
    position: absolute;
    top: 17rem;
    right: 2rem;
    // width: 75%;
  }

  &__heading-span {
    padding: 0.4rem 1.5rem;
    -webkit-box-decoration-break: clone;
    &--1 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-secondary-light, 0.85),
        rgba($color-secondary-dark, 0.85)
      );
    }
    &--2 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.85),
        rgba($color-primary-dark, 0.85)
      );
    }
    &--3 {
      background-image: linear-gradient(
        to right bottom,
        rgba($color-tertiory-light, 0.85),
        rgba($color-tertiory-dark, 0.85)
      );
    }
  }

  &__details {
    padding: 3rem;
    ul {
      list-style: none;
      width: 80%;
      margin: 0 auto;
      li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem;
        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-light-2;
        }
      }
    }
  }

  //BACK SIDE STYLING
  &__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }
  &__price-box {
    text-align: center;
    color: $color-white;
    margin-bottom: 8rem;
  }
  &__price-only {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  &__price-value {
    font-size: 6rem;
    font-weight: 100;
  }
  @include respond(tab-port) {
    //FUNCTIONALITY
    height: auto;
    border-radius: 3px;
    background-color: $color-white;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.5);

    &__side {
      height: auto;
      position: relative;
      overflow: hidden;
      box-shadow: none;

      &--back {
        // background-color: green;
        transform: rotateY(0);
        clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 100%);
      }
    }

    &:hover &__side--front {
      transform: rotateY(0);
    }

    &__details {
      padding: 1rem 3rem;
    }

    //FRONT PAGE STYLING

    //BACK SIDE STYLING
    &__cta {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0);
      width: 100%;
      padding: 7rem 4rem 4rem 4rem;
    }
    &__price-box {
      margin-bottom: 8rem;
    }

    &__price-value {
      font-size: 6rem;
    }
  }
}

.flex-card {
  display: flex;
  gap: 2rem;
  padding: 4rem;
  border-radius: 10px;
  // justify-content: center;
  color: $color-white;
  align-items: center;
  background-color: $professional-card-color-1;
  &__heading {
    font-size: 2.2rem;
    text-align: start;
    margin-bottom: 1rem;
    font-weight: 200;
  }
  &__passage {
    font-size: $default-font-size;
    text-align: start;
  }
}

// MIN HEIGHT CARDS

.ms-card {
  min-height: 21.2rem;
}
.sa-card {
  min-height: 24rem;
}
.as-card {
  min-height: 20rem;
}
