.aboutUs {
  &__heading-primary {
    font-size: 4.5rem;
    color: #004188;
    text-align: center;
  }
  &__heading-secondary {
    font-size: 5.5rem;
    color: $color-black;
    max-width: 800px;
    display: inline-block;
  }
  &__heading-tertiory {
    font-size: 3.5rem;
    color: $color-white;
    max-width: 800px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 2rem;
  }
  &__passage {
    font-size: 2.4rem;
  }
  &__paragraph {
    font-size: 2.4rem;
    color: #8796a5;
  }
}

.aboutUs-top-container {
  background-image: radial-gradient(
      circle at center,
      $page-bg-color-2,
      $page-bg-color-1 //   $color-grey-light-1,
      //   $color-grey-light-2
    ),
    url("../../assets/globe.png");
  background-size: cover;
  padding: 10rem 0;
  &__img {
    width: 100%;
  }
}

.aboutUs-Why-choose-us {
  text-align: center;
  &__img {
    width: 80%;
  }
  &__passage {
    padding: 1% 10%;
    font-size: $default-font-size;
    margin-bottom: 2rem;
  }
}

.WCU-card {
  display: flex;
  box-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  border-radius: 4px;
  padding: 2rem;
  gap: 1.5rem;
  margin-top: 4rem;
  min-height: 19rem;
  &__text-card {
    text-align: start;
    &__heading {
      color: $professional-color1;
      font-size: 2.4rem;
      font-weight: 100;
      margin-bottom: 1rem;
    }
    &__paragraph {
      font-size: $default-font-size;
    }
  }
}
