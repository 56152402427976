.feature-box {
  background-color: rgba($color-white, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba($color-white, 0.15);
  transition: transform 0.3s;

  @include respond(tab-port) {
    padding: 2rem;
  }

  &__icon {
    font-size: 8rem;

    background-image: linear-gradient(
      to right,
      $color-primary-light,
      $color-primary-dark
    );
    padding: 10px 0 0 0;
    -webkit-background-clip: text;
    color: transparent;
    @include respond(tab-port) {
      margin-bottom: 0;
    }
  }
  &:hover {
    transform: translateY(-1.5rem) scale(1.03);
  }
}
