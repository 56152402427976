.domains {
  &__img {
    width: 100%;
    border-radius: 5px;
  }
  &__heading {
    font-size: 2.6rem;
    color: $professional-color1;
  }
  &__paragraph {
    font-size: 1.7rem;
    // color: $color-black;
    margin-bottom: 1rem;
  }
}
