.composition {
  position: relative;

  &__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.4);
    // border-radius: 2px;
    position: absolute;
    z-index: 10;
    transition: all 0.2s;
    outline-offset: 1.5rem;

    @include respond(tab-port) {
      float: left;
      position: relative;
      width: 33.3333%;
      box-shadow: 0 1.5rem 3rem rgba($color-black, 0.2);
    }
    &--p1 {
      left: 0;
      top: -2rem;
      @include respond(tab-port) {
        top: 0;
        transform: scale(1.2);
      }
    }
    &--p2 {
      right: 0;
      top: 2rem;
      @include respond(tab-port) {
        top: -1rem;
        transform: scale(1.3);
        z-index: 100;
      }
    }
    &--p3 {
      left: 20%;
      top: 10rem;
      @include respond(tab-port) {
        top: 1rem;
        left: 0;
        transform: scale(1.2);
      }
    }
    &:hover {
      outline: 1.5rem solid $color-primary;
      transform: scale(1.05) translateY(0.5rem);
      box-shadow: 0 2.5rem 4rem rgba($color-black, 0.5);
      z-index: 20;
    }
  }
  &:hover &__photo:not(:hover) {
    transform: scale(0.95);
  }
}
