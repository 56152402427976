.header {
  height: 95vh;
  // background-image: linear-gradient(
  //   to right bottom,
  //   $color-primary-light,
  //   $color-primary-dark
  // );
  @include respond(tab-port) {
    background-image: url(../../assets/header-wallpaper-sm1.png);
    width: 100%;
    height: 100vh;
    background-size: cover;
    // background-position: bottom center 10%;
    background-position: bottom center;
  }
  @include respond(phone) {
    // clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%);
    // background-position: bottom center 50%;
    background-image: url(../../assets/header-wallpaper-sm1.png);
    width: 100%;
    height: 38vh;
  }
  background-color: $color-white;
  // background-position: center left 30%;
  // url(../img/header-wallpaper3.jpeg);
  background-size: cover;
  // background-position: bottom center 50%;
  width: 100%;
  // background-image: radial-gradient(
  //   circle at right bottom,
  //   $color-primary-light 48%,
  //   #ffffff 25%
  // );
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  // clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
  // clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 50%);
  // clip-path: circle(50% at 50% 50%);
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0% 50%);
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0% 50%)
  // clip-path: circle(700px at 100% 100%);
  // circle(100px at 0 50%);
  position: relative; /* parent element */
  // background-position: center top -20%;

  // margin-right: 0px;

  @include respond(phone) {
    // clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%);
    // background-position: bottom center 50%;
  }

  &__logo-box {
    position: absolute; /* child element */
    top: 2rem;
    left: 4.5rem;
    background-color: $color-white;
    box-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
  }
  &__logo {
    height: 7.2rem;
    @include respond(tab-port) {
      height: 6.2rem;
    }
    @include respond(phone) {
      height: 4.8rem;
      top: 2rem;
      left: 2.5rem;
    }
  }

  &__right-part {
    position: absolute;
    height: 11rem;
    border-radius: 10px;
    // background-color: white;
    width: 58rem;
    display: inline-block;
    padding: 20px;
    top: 1rem;
    right: 4rem;
    z-index: 150;
    // background-color: #7bc3e7b0;
    &--mail {
      // float: right;
      display: inline-block;
      font-size: 1.3rem;
      margin-left: 5rem;
      margin-top: 0.5rem;
      // color: #fff;
    }

    &--button {
      float: right;
      // background-color: #3579be;
      outline: none;
      margin-top: -4px;
      margin-right: 30px;
      border: 0;
      padding: 6px 20px;
      padding-top: 8px;
      border-radius: 4px;
      color: white;
      text-transform: uppercase;
    }

    &--sections {
      // position: absolute;
      // background-color: aqua;
      // top: 4rem;
      margin-top: 30px;
      text-align: right;

      span {
        font-size: 1.8rem;
        // color: #000;
        // color: #fff;
        color: #235196;
        cursor: pointer;
        padding: 10px;
        font-weight: 600;
        margin-right: 2rem;
        &:not(:last-child) {
          margin-right: 12px;
          // background-color: aqua;
        }

        &:hover {
          color: $color-primary-dark;
          border: 2px solid $color-primary-light;
          padding: 6px 8.3px 8px 8.3px;
          border-radius: 10px;
        }
      }
      // &--item {
      //   border: 1px solid $color-primary-dark;
      //   // padding: -12px -4px;
      //   padding: 0;
      //   border-radius: 10px;
      //   color: $color-primary-dark;
      // }
    }
    @include respond(tab-land) {
      display: none;
    }
  }

  &__text-box {
    position: absolute;
    top: 60%;
    left: 28%;
    width: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    // background-color: #235196;
    z-index: 151;
    @include respond(tab-port) {
      display: none;
      width: 100%;
      // background-color: #235196;
      left: 42%;
      top: 20%;
      transform: translate(-50%, -50%);
    }
  }
  &__image {
    // padding: 220px;
    @include respond(tab-port) {
      display: none;
    }

    position: absolute;
    top: 55.5%;
    left: 75.5%;
    width: 50%;
    transform: translate(-50%, -50%);
    background-position: center right 50%;
  }
  &__bg {
    @include respond(tab-port) {
      display: none;
    }
    position: absolute;
    top: 38.5%;
    left: 74.5%;
    width: 52%;
    height: 100vh;
    transform: translate(-50%, -50%);

    // background-image: radial-gradient(
    //   circle at right bottom,
    //   $color-primary-light 48%,
    //   transparent 100%
    // );
    // overflow: hidden;
    background-image: radial-gradient(
      circle at right bottom,
      $color-primary-light-6 68%,
      white 25%
    );
  }
  &__container-bg {
    position: absolute;
    z-index: 50;
    height: 100vh;
    width: 100%;
    // background-image: linear-gradient(
    //   to right bottom,
    //   $color-primary-light-5,
    //   $color-primary-dark-5
    // );

    // clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0% 83%, 100% 0%, 100% 100%, 0% 100%);
    // background-color: #000000;
    @include respond(tab-port) {
      display: none;
    }
  }
}

.header-space {
  margin-bottom: 15rem;
}

//menu bar

.menu-bar {
  text-align: center;
  // background-color: orange;
}
.link-item {
  text-decoration: none;
}
.menu-bar ul {
  display: inline-flex;
  list-style: none;
  color: $professional-color2;
  text-decoration: none;
}
.menu-bar ul li {
  width: 100px;
  list-style: none;
  font-size: $default-font-size;
}

.menu-bar ul li a {
  text-decoration: none;
  // color: #1062bd;
  color: $professional-color1;
  transition: all 0.1s;
  font-weight: 500;
  // list-style: none;
  // font-size: 1.8rem;
}

.arrow-icon {
  font-size: 12rem;
  display: inline-block;
  background-color: #1062bd;
}

.active,
.menu-bar ul li a:hover {
  // background-color: #235196;
  border-radius: 10px;
  padding: 10px;
  // border: 2px solid #0e6ace;
  // border: 2px solid #0f87e2;
  // color: $professional-color2;
  border: 2px solid $professional-color2;
}

.sub-menu-1 {
  display: none;
}

.sub-menu-1 ul li a {
  color: #fff;
}

.menu-bar ul li:hover .sub-menu-1 {
  display: block;
  position: absolute;
  background-color: $professional-color2;
  box-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
  border-radius: 3px;
  margin-top: 12px;
  margin-left: 10px;
  // padding: 20px;
}
.menu-bar ul li:hover .sub-menu-1 ul {
  display: block;
}
.menu-bar ul li:hover .sub-menu-1 ul li {
  width: 188px;
  padding: 15px 20px;
  border-bottom: 10px dotted #fff;
  background: transparent;
  border-radius: 0;
  text-align: left;
}

.menu-bar ul li.active,
.menu-bar ul li.active > a,
.menu-bar ul li:hover > a {
  border: 2px solid $professional-color2;
  border-radius: 10px;
  padding: 10px;
}

.sub-menu-1 ul li.active,
.sub-menu-1 ul li.active > a,
.sub-menu-1 ul li:hover > a {
  border: none;
  // border-radius: 10px;
  padding: 0;
}

.menu-bar ul li:hover .sub-menu-1 ul li:hover {
  background-color: $professional-color3;
  border: none;
  border-radius: 3px;
  border-bottom: 1px dotted #fff;
}

.menu-bar ul li:hover .sub-menu-1 ul li:last-child {
  border-bottom: none;
}

.menu-bar ul li:hover .sub-menu-1 ul li a:hover {
  // color: orange;
  padding: 0;
  border: none;
}

.angle-right {
  float: right;
}

.sub-menu-2 {
  display: none;
}

.hover-me:hover .sub-menu-2 {
  position: absolute;
  display: block;
  margin-top: -34px;
  margin-left: 130px;
  background-color: #1062bd;
}

.domains-li-item {
  min-width: 120px;
  // background-color: #0e6ace;
}
.contact-us-li-item {
  min-width: 120px;
}
