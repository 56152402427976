.section-about {
  background-color: $color-grey-light-1;
  padding: 25rem 0;
  margin-top: -20vh;

  @include respond(tab-port) {
    padding: 20rem 0;
    margin-top: -12vh;
  }
}

.section-features {
  padding: 20rem 0;
  background-image: linear-gradient(
      to right bottom,
      $color-primary-light,
      $color-primary-dark
    ),
    url("../../assets/solutions-wallpaper.jpg");
  background-size: cover;
  // background-position: top;
  margin-top: -10rem;
  transform: skewY(-4deg);
  & > * {
    transform: skewY(4deg);
  }

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.section-tours {
  background-color: $color-grey-light-1;
  padding: 25rem 0 10rem 0;
  margin-top: -10rem;

  @include respond(tab-port) {
    padding: 20rem 0 10rem 0;
  }
}

.section-stories {
  padding: 15rem 0;
  // background-color: orangered;
  // background-color: rgba($color-white, 0.6);
  position: relative;
  margin-top: 0rem;

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.section-book {
  padding: 15rem 0;
  background-image: linear-gradient(
    to right bottom,
    $color-primary-light,
    $color-primary-dark
  );
  // box-shadow: 0rem 2rem rgba($color-black, 0.1);
  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.book {
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 50%,
      transparent 50%
    ),
    url(../../assets/nat-10.jpg);
  background-size: 100%;
  height: 50rem;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.2);
  @include respond(tab-land) {
    background-image: linear-gradient(
        105deg,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 70%,
        transparent 70%
      ),
      url(../../assets/nat-10.jpg);
    background-size: cover;
  }
  @include respond(tab-port) {
    background-image: linear-gradient(
        to right,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 100%
      ),
      url(../../assets/nat-10.jpg);
  }
  &__form {
    width: 50%;
    padding: 6rem;
    @include respond(tab-land) {
      width: 70%;
    }
    @include respond(tab-port) {
      width: 100%;
    }
  }
}
