/* YourComponent.css */

/* Custom CSS for the left scroll animation */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Adjust other styles as per your requirements */
