@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//MEDIA QUERY MANAGER

/* 
0-600 phone
600-900 tablet portrait  
900 -1200 tablet landscape 
1200 - 1800 is where normel styles apply
1880px+ desktops
*/

// ORDER: Base + typography >genereal layout > grid> page layout > components

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 1120.5em) {
      @content;
    }
  }
}
