.u-center-text {
  text-align: center;
}

.u-margin-bottom-small {
  margin-bottom: 1.2rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
  @include respond(tab-port) {
    margin-bottom: 3rem;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem;
  @include respond(tab-port) {
    margin-bottom: 5rem;
  }
}

.u-margin-top-small {
  margin-top: 1.2rem;
}

.u-margin-top-medium {
  margin-top: 4rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}

.u-margin-top-huge {
  margin-top: 10rem;
}

.u-margin-top-very-huge {
  margin-top: 15rem;
}

.u-text-color-white {
  color: $color-white;
}
.sm-text-center {
  @include respond(tab-port) {
    text-align: center;
  }
}
