//COLORS

$professional-color1: #004188;
$professional-color2: #0e6ace;
$professional-color3: #1062bd;

$professional-card-color-1: #0d0d4f;

$color-primary: #21c4fa;
// $color-primary-light: #7ed56f;
// $color-primary-dark: #28b485;

$color-primary-light-5: #21c4fa;
$color-primary-dark-5: #05b2f64d;

$color-primary-light: #21c4fa9d;
$color-primary-dark: #05b2f6d8;

$color-primary-light-6: #21c4fa14;

$color-primary-light-1: #21c4fa;
$color-primary-dark-1: #05b2f6;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiory-light: #2998ff;
$color-tertiory-dark: #5643fa;

$color-grey: #393737;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

//BUTTON COLORS

$button-color-light: #62d4f7;
$button-color-dark: #3579be;

$button-color-light-1: #3579be;
$button-color-dark-1: #000036;

$button-border-color: #0e6ace;
$button-color-primary: #1062bd;

//PAGES COLORS

$page-bg-color-1: #000053;
$page-bg-color-2: #000071af;

//FONT SIZES
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;

$gutter-horizontal: 6rem;
